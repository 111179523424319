import { AjaxForm } from "@neonjungle/birdseed/forms/ajax";
import { proseBlocks } from "./animations";

class URLUpdatingAjaxForm extends AjaxForm {
	afterFetch(responseJson) {
		window.history.pushState({}, "", responseJson.url);
		proseBlocks();
	}

	getFetchOptions(formMethod) {
		let fetchOptions = super.getFetchOptions(formMethod);
		fetchOptions.cache = 'no-store';
		return fetchOptions
	}
}

export default function submitOnChange() {
	document.querySelectorAll("form[data-submit-on-change]").forEach(formEl => {
		const target = document.querySelector("[data-search-results]");
		const ajaxForm = new URLUpdatingAjaxForm(formEl, target);
		formEl.querySelectorAll("input, select").forEach(el => {
			el.addEventListener("change", e => {
				ajaxForm.fetchAndReplace();
			});
		});

		window.onpopstate = () => {
			// When hitting back, reload the form
			window.location.reload();
		}
	});
}
