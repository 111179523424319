function Accordion(component) {
	const sections = Array.from(
		component.querySelectorAll(".accordion__section")
	);
	const toggles = Array.from(
		component.querySelectorAll(".accordion__section__head")
	);

	sections.forEach(s => {
		const title = s.querySelector(".accordion__section__head");
		s.style.height = `${title.offsetHeight}px`;
		if (!s.classList.contains("is-open")) {
			s.style.overflow = "hidden";
		}
	});

	function setSizing() {
		sections.forEach(s => {
			const title = s.querySelector(".accordion__section__head");
			const body = s.querySelector(".accordion__section__body");

			if (s.classList.contains("is-open")) {
				s.style.height = `${title.offsetHeight + body.offsetHeight}px`;
			} else {
				s.style.height = `${title.offsetHeight}px`;
			}
		});
	}
	setSizing();

	toggles.forEach(title => {
		title.addEventListener("click", e => {
			e.preventDefault();
			const section = title.parentNode;
			const body = section.querySelector(".accordion__section__body");

			if (section.classList.contains("is-open")) {
				section.classList.remove("is-open");
				section.classList.add("is-animating");
				section.style.overflow = "hidden";
				requestAnimationFrame(() => {
					section.addEventListener("transitionend", onEnd);
					section.style.height = `${title.offsetHeight}px`;
					function onEnd() {
						section.classList.remove("is-animating");
						section.removeEventListener("transitionend", onEnd);
					}
				});
			} else {
				section.classList.add("is-open");
				section.classList.add("is-animating");
				requestAnimationFrame(() => {
					section.addEventListener("transitionend", onEnd);
					section.style.height = `${title.offsetHeight + body.offsetHeight}px`;
					function onEnd() {
						section.style.overflow = "visible";
						section.classList.remove("is-animating");
						section.removeEventListener("transitionend", onEnd);
					}
				});

				// close all other sections
				if (component.classList.contains("accordion--limit-to-one-open")) {
					sections.forEach(otherSect => {
						const otherTitle = otherSect.querySelector(
							".accordion__section__title"
						);
						if (
							otherSect != section &&
							otherSect.classList.contains("is-open")
						) {
							otherSect.classList.remove("is-open");
							otherSect.classList.add("is-animating");
							otherSect.style.overflow = "hidden";
							requestAnimationFrame(() => {
								otherSect.addEventListener("transitionend", onEnd);

								otherSect.style.height = `${otherTitle.offsetHeight}px`;
								function onEnd() {
									otherSect.classList.remove("is-animating");
									otherSect.removeEventListener("transitionend", onEnd);
								}
							});
						}
					});
				}
			}
		});
	});

	window.addEventListener("resize", setSizing);
}

function initAccordion() {
	Array.from(document.querySelectorAll(".accordion")).forEach(Accordion);
}

export { initAccordion, Accordion };
