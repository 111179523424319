import "../scss/app.scss";

// SVGs to make into sprite, paths relative to app.js before move
var __svg__ = {
	path: "../svgs/sprite/*.svg",
	name: "../../brandtas/jinja2/svgs/sprite.svg"
};

// Polyfill all the things...
import "whatwg-fetch";
// For anything not-DOM (eg String.repeat, Promise, Array.fill etc) use core-js.
// See available options here -> https://github.com/zloirock/core-js#features
import "core-js/es/array/from";
import "core-js/features/promise";
import "@ungap/custom-elements";

// We manually include the DOM polyfills we believe are necesary.
import applyDomPolyfills from "./includes/dompolyfills";

applyDomPolyfills();

// Production code goes after here
import Plyr from "plyr";

import { ValidatedForm } from "@neonjungle/birdseed/forms/validatedform";
import { OutdatedBrowserNotice } from "@neonjungle/birdseed/browser/OutdatedBrowserNotice";

import animations from "./includes/animations";
import menuActions from "./includes/menuActions";
import attributesForCSS from "./includes/attributesForCSS";
import updateClampedCSS from "./includes/updateClampedCSS";
import submitOnChange from "./includes/submitOnChange";
import setupSearch from "./includes/siteSearch";
import { initProgressRings } from "./includes/ProgressRing";
import { initAccordion } from "./includes/Accordion";
import { addBackToTop } from "vanilla-back-to-top";
import setupSubscribeForms from "./includes/subscribeForm";

// Should happen before the page has finished loading
// to update font sizes ASAP
updateClampedCSS();
animations();

document.addEventListener("DOMContentLoaded", function () {
	menuActions();
	attributesForCSS();
	submitOnChange();
	setupSearch();
	initProgressRings();
	initAccordion();
	setupSubscribeForms();
	addBackToTop({
		backgroundColor: "var(--t-accent-colour)",
		zIndex: 102,
		diameter: 44,
		innerHTML:
			'\
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" \
			viewBox="0 0 40 40" style="enable-background:new 0 0 40 40;" xml:space="preserve"> \
   		<g> \
	   		<path fill="#FFFFFF" d="M40,40H0V0h40V40z M1,39h38V1H1V39z"/> \
	   		<path fill"#FFFFFF" d="M20.07,12.24l3.86,6.69l3.92,6.67c0.05,0.08,0.02,0.18-0.06,0.23c-0.03,0.01-0.05,0.02-0.08,0.02H12.29 \
		   		c-0.06,0-0.12-0.03-0.15-0.08c-0.02-0.03-0.02-0.06-0.02-0.09c0-0.03,0.01-0.06,0.03-0.09l3.78-6.67l3.85-6.68 \
		   		c0.03-0.05,0.08-0.09,0.15-0.08C19.99,12.15,20.04,12.19,20.07,12.24 M17.94,11.18l-3.86,6.68l-3.78,6.67 \
		   		c-0.63,1.1-0.26,2.5,0.84,3.13c0.35,0.2,0.75,0.31,1.15,0.31h15.41c1.26,0,2.29-1.02,2.29-2.29c0-0.41-0.11-0.81-0.31-1.16 \
		   		l-3.92-6.67l-3.85-6.67c-0.63-1.1-2.04-1.47-3.13-0.84C18.43,10.54,18.14,10.83,17.94,11.18"/> \
   		</g> \
   		</svg>'
	});

	const players = Array.from(
		document.querySelectorAll(".video-player video")
	).map(
		p =>
			new Plyr(p, {
				controls: [
					"progress",
					"play",
					"mute",
					"current-time",
					"captions",
					"fullscreen"
				],
				hideControls: false
			})
	);

	new OutdatedBrowserNotice(
		{
			ie: ">10",
			safari: ">13",
			opera: ">76",
			edge: ">90",
			chrome: ">90",
			firefox: ">88",
			samsung_internet: ">13"
		},
		browserOutdated => {
			if (browserOutdated) {
				document.body.classList.add("browser-is-outdated");
			}
		}
	);

	document.body.classList.remove("preload");

	const validatedForms = document.querySelectorAll(
		"form.l-form:not([data-no-validate])"
	);
	validatedForms.forEach($f => new ValidatedForm($f));
});
